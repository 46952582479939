const homePath = '/admin';

export { homePath };

export const UNAUTHENTICATED_ROUTE_TERMINALS = {
  Login: 'login',
} as const;

export const AUTHENTICATED_ROUTE_TERMINALS = {
  ROOT: 'admin',
  RiskAssessments: {
    ROOT: 'systemRiskAssessmentDocuments',
    Show: 'show',
    Details: `:id`,
    Answers: '1',
    Risks: '2',
  },
  Risks: {
    ROOT: 'systemRiskAssessmentDocumentRisks',
    Show: 'show',
    Details: `:id`,
  },
  Organizations: {
    ROOT: 'systemOrganizations',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    Members: '1',
  },
  Users: {
    ROOT: 'systemUsers',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    Organization: '1',
  },
  VendorAssemsments: {
    ROOT: 'systemVendorRisks',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    QuestionnaireInformation: '1',
    Dealerships: '2',
  },
  Vendors: {
    ROOT: 'systemVendors',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
  },
} as const;

export const ROUTES = {
  Root: `/${AUTHENTICATED_ROUTE_TERMINALS.ROOT}/*`,
  Login: `/${UNAUTHENTICATED_ROUTE_TERMINALS.Login}`,
  RiskAssessments: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}`,
    RiskAssessment: {
      Root: `:RiskAssessmentId/*`,
      show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}`,
        answers: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Answers}`,
        risks: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Risks}`,
      },
    },
  },
  Organizations: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}`,
    Organization: {
      Root: `:OrganizationId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}`,
        Members: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Members}`,
      },
    },
  },
  Users: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}`,
    User: {
      Root: `:UserId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}`,
        Members: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Organization}`,
      },
    },
  },
  VendorAssesments: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}`,
    VendorAssessment: {
      Root: `:VendorAssessmentId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}`,
        QuestionnaireInformation: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.QuestionnaireInformation}`,
        Dealerships: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Dealerships}`,
      },
    },
  },
  Vendors: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}`,
    Vendor: {
      Root: `:VendorId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Show}`,
      },
    },
  },
} as const;

export const PATHS = {
  ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.ROOT}`,
  get Login() {
    return `/${UNAUTHENTICATED_ROUTE_TERMINALS.Login}` as const;
  },
  get RiskAssessments() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}`,
      RiskAssessment: {
        ROOT: (RiskAssessmentId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}/${RiskAssessmentId}` as const,
        Show(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}` as const;
        },
        Edit(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}` as const;
        },
        Answers(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Answers
          }` as const;
        },
        Risks(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Risks
          }` as const;
        },
      },
    };
  },
  get Risks() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.ROOT}`,
      Risk: {
        ROOT: (RiskId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.ROOT}/${RiskId}` as const,
        Show(RiskId: string) {
          return `${this.ROOT(RiskId)}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.Show}` as const;
        },
      },
    };
  },
  get Organizations() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}`,
      Organization: {
        ROOT: (OrganizationId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${OrganizationId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}` as const,
        Show(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}` as const;
        },
        Edit(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}` as const;
        },
        Members(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.Organizations.Members
          }` as const;
        },
      },
    };
  },
  get Users() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}`,
      User: {
        ROOT: (UserId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
        Organization(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.Users.Organization
          }` as const;
        },
      },
    };
  },
  get VendorAssessments() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}`,
      get Create() {
        return `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}`;
      },
      VendorAssessment: {
        ROOT: (UserId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
        QuestionnaireInformation(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.QuestionnaireInformation
          }` as const;
        },
        Dealership(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Dealerships
          }` as const;
        },
      },
    };
  },
  get Vendors() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}`,
      Vendor: {
        ROOT: (UserId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
      },
    };
  },
} as const;

export const RISK_ASSESSMENTS_HOME_PATH = PATHS.RiskAssessments.ROOT;
export const ORGANIZATION_HOME_PATH = PATHS.Organizations.ROOT;
export const USERS_HOME_PATH = PATHS.Users.ROOT;
export const VENDOR_ASSESSMENTS_HOME_PATH = PATHS.VendorAssessments.ROOT;
export const VENDORS_HOME_PATH = PATHS.Vendors.ROOT;
