import { Button as NextButton, ButtonProps as NextButtonProps, Loading } from '@nextui-org/react';
import React from 'react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { lightTheme } from '../../theme/Themes';

enum ButtonRoleEnum {
  Cancel,
  Destructive,
  Primary,
}

export class ButtonRole {
  static Cancel: ButtonRole = new ButtonRole(ButtonRoleEnum.Cancel);
  static Destructive: ButtonRole = new ButtonRole(ButtonRoleEnum.Destructive);
  static Primary: ButtonRole = new ButtonRole(ButtonRoleEnum.Primary);

  constructor(private buttonRole: ButtonRoleEnum) {}

  backgroundColor(theme = lightTheme): string {
    switch (this.buttonRole) {
      case ButtonRoleEnum.Destructive:
        return theme.colors.darkRed;
      default:
        return theme.colors.primary;
    }
  }
}

interface ButtonProps extends NextButtonProps {
  loading?: boolean;
  loadingSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disableMinWidth?: boolean;
}

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Button = styled(({ children, loading, loadingSize = 'md', ...props }: ButtonProps) => (
  <NextButton {...props}>
    {loading && <StyledLoading size={loadingSize} />}
    {children}
  </NextButton>
)).attrs((props: ButtonProps & ThemeProp) => ({
  ...props,
  css: {
    position: 'relative',
    'pointer-events': props.loading ? 'none' : 'auto',
    'border-radius': '$xxs',
    'font-size': '$base',
    'font-weight': '$normal',
    height: '$14',
    'min-width': props.disableMinWidth ? undefined : '$25',
    padding: '$6a $8',
    color: props.loading ? 'transparent' : props.color,
    gap: '$5',
    '&:disabled': {
      background: props.theme.colors.primary,
      opacity: 0.2,
      color: props.theme.colors.white,
    },
    ...props.css,
  },
}))`
  .nextui-loading {
    --nextui--loadingColor: ${(props: ThemeProp) => props.theme.colors.c100};
  }
  .nextui-button-icon {
    margin-right: 0;
  }
`;

export default Button;
